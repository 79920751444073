<template>
  <section class="commentsRecord">
    <div class="commentsRecord__contentMsg">
      <div class="commentsRecord__addMsg">
        <iconic class="commentsRecord__icon" name="user_contact" />
        <span>Añadir comentario</span>
      </div>
    </div>
    <div class="commentsRecord__contentMsg" v-for="comment in comments" :key="comment.idx">
      <div class="commentsRecord__comentDetails">
        <div class="commentsRecord__userData">
          <Avatar class="commentsRecord__avatar" :user="comment.user" />
          <span class="commentsRecord__userName">{{ comment.user.user }}</span>
        </div>
        <span class="commentsRecord__dateComment">{{ formatDate(comment.date) }}</span>
      </div>
      <p class="commentsRecord__message">{{ comment.comment }}</p>
    </div>
  </section>
</template>

<script>
export default {
  props: ["comments", "recordId"],
  components: {
    Avatar: () => import("@/common/Avatar.vue"),
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return "";
      }
      const dateParts = new Date(date).toLocaleString("es-CO", { dateStyle: "long" }).split("de");
      dateParts.splice(2, 0, "de");

      return dateParts.join("");
    },
  },
};
</script>

<style lang="scss">
.commentsRecord {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: 15px;
  &__contentMsg {
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    gap: 8px;
    padding: 10px;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    background: #ecf2f8;
  }
  &__addMsg {
    @include Flex(row, flex-start);
    flex-wrap: nowrap;
    gap: 8px;
  }
  &__icon {
    padding: 0 2px;
    color: #5b5957;
    cursor: pointer;
  }
  &__comentDetails {
    @include Flex(row, space-between);
    flex-wrap: nowrap;
    width: 100%;
    gap: 10px;
  }
  &__userData {
    @include Flex(row);
    gap: 5px;
  }
  &__avatar {
    height: 24px;
    width: 24px;
    font-size: 12px;
    border: 1px solid $alto;
    transition: 0.3s ease-in-out;
  }
  &__dateComment {
    font-size: 11.5px;
    color: #575757;
  }
  &__message {
    color: #1c1b1b;
    font-weight: 400;
  }
}
</style>
